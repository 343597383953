import React from "react";
import { capitalize } from "lodash";
import { useNavigate } from "react-router-dom";
import { GrDocumentPdf } from "@react-icons/all-files/gr/GrDocumentPdf";

import { Box, Button, Flex, Header, Link, Text } from "Atoms";
import { useLanguage } from "Providers";

const TermsAndConditions: React.FC<{}> = () => {
    const { translate, userLanguage } = useLanguage();
    const navigate = useNavigate();
    return (
        <Flex flexDirection="column" width="100%" alignItems="center">
            <Flex
                w="100%"
                h="250px"
                backgroundImage={`url("https://qopla.s3.eu-west-1.amazonaws.com/qopla/q_bg.png")`}
                backgroundSize="cover"
                backgroundPosition="center"
                backgroundRepeat="repeat"
                justifyContent="center"
                alignItems="center"
            >
                <Header color="white">{capitalize(translate("termsOfUseSingle"))}</Header>
            </Flex>

            <Flex flexBasis="min-content" w="100%" textAlign="left">
                <Button
                    ml={{ xs: "0", md: "4" }}
                    mt="4"
                    onClick={() => navigate(-1)}
                    variant="link"
                    size="md"
                    themeColor="blue"
                    _focus={{ boxShadow: "outline" }}
                >
                    {translate("back")}
                </Button>
            </Flex>
            <Flex
                w="100%"
                flexBasis="min-content"
                flexDirection="column"
                textAlign="left"
                p={{ xs: "4", lg: "12", xl: "24" }}
                borderBottom="1px solid"
                borderColor={"gray.200"}
                boxShadow={"md"}
            >
                <Text>{translate("findOurTermsAndAgreement")}</Text>
                <Link
                    href={`https://qopla.s3.eu-west-1.amazonaws.com/qopla/policy/qopla_terms_of_use_${userLanguage}.pdf`}
                >
                    <Box as={GrDocumentPdf} size="15px" mr="2" />
                    Qopla {translate("termsOfUseSingle")}
                </Link>
            </Flex>
        </Flex>
    );
};

export default TermsAndConditions;
